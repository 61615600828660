<template>
	<!-- <div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div> -->
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<h4 class="p-m-2">Emisión de Cargos Programados</h4>
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-6">
								<label for="categoria">Categoría</label>
								<Dropdown id="categoria" v-model="condiciones.id_categoria" :options="lkpCategorias" optionLabel="categoria" optionValue="id_cta_habiente_categoria" placeholder="Categoría..." />						
							</div>	
							<div class="p-field p-col-12 p-md-6">
								<label for="tasa_cambio">Tasa de Cambio</label>
								<InputNumber id="tasa_cambio" v-model="condiciones.tasa_cambio" mode="decimal" :minFractionDigits="2" :maxFractionDigits="4"/>										
							</div>														
							<div class="p-field p-col-12 p-md-6">
								<Button icon="pi pi-cog" label="Calcular" @click="generar" />								
							</div>														
							<div class="p-field p-col-12 p-md-6">
								<Button icon="pi pi-cog" label="Emitir" @click="emitir" :disabled="totalTrxAEmitir.length==0"/>	
							</div>														
							<!-- <div class="p-field-checkbox p-col-3 p-mt-4">
								<label for="anulados">Mostrar Anulados</label>
								<Checkbox id="anulados" v-model="mostrar_anulados" :binary="true" />							
							</div>														 -->												
						</div>																													
					</template>
				</Toolbar>								
        <DataTable :value="cargos" editMode="cell" dataKey="id_movimiento" :rowClass="rowClass" v-model:expandedRows="expandedRows" responsiveLayout="scroll" class="p-datatable-sm"
		filterDisplay="menu" :loading="loading">
			<template #empty>
				No ha contratos
			</template>		
             <Column :expander="true" headerStyle="width: 3rem" />             
            <Column field="emitir" headerStyle="width: 3em">
				<template #header>
					<i class="pi pi-cog"/>
				</template>			
				<template #body="slotProps">
					<Checkbox v-bind:id="slotProps.data.id_movimiento" v-model="slotProps.data[slotProps.column.props.field]" :binary="true" :disabled="slotProps.data.emitido"/>			
				</template>
			</Column>             
            <Column field="cta_habiente" header="Cliente"/>             
            <!-- <Column field="vigencia_final" header="Vencimiento"/>                    -->
            <!-- <Column field="plantilla" header="Plan"/> -->
			<Column field="referencia" header="Referencia"/>
            <Column field="fecha" header="Fecha"/>               
            <Column field="doc_fecha" header="Fecha Emisión"/>                          
            <Column field="doc_emision" header="Documento"/>   
			<Column field="fecha_pago" header="Vencimiento"/>                                      
            <Column field="total" header="Total"> 
				<template #body="slotProps">
					{{slotProps.data.id_moneda}} {{formatCurrency(slotProps.data.total)}}
				</template>
			</Column>	
			<Column field="enviar_notificacion" headerStyle="width: 3em">
				<template #header>
					<i class="pi pi-envelope"/>
				</template>			
				<template #body="slotProps">
					<Checkbox v-bind:id="slotProps.data.enviar_notificacion" v-model="slotProps.data[slotProps.column.props.field]" :binary="true" :disabled="slotProps.data.emitido"/>			
				</template>
			</Column>    								    
            <template #expansion="slotProps">
                <div class="orders-subtable">
                    <DataTable :value="slotProps.data.detalle" dataKey="id_mov_det" responsiveLayout="scroll" class="p-datatable-sm"  selectionMode="single">
                        <Column field="codigo" header="Código" style="flex: 0 0 8rem"></Column>
                        <Column field="producto" header="Producto" style="flex: 0 0 2rem"></Column>
                        <Column field="cantidad" header="Cantidad" style="flex: 0 0 2rem"></Column>
						<Column field="precio_unitario" header="Precio U." style="flex: 0 0 4rem">
                          <template #body="slotProps">
                                {{formatCurrency(slotProps.data.precio_unitario)}}
                            </template>
                        </Column>
						<Column field="total" header="Total">					
                            <template #body="slotProps">
                                {{formatCurrency(slotProps.data.total)}}
                            </template>
                        </Column>							
                    </DataTable>
                </div>
            </template>
			<ColumnGroup type="footer">
				<Row>
					<Column footer="Documentos:" :colspan="3" footerStyle="text-align:right" />
					<Column :footer="totalTrxAEmitir.length"/>
					<Column footer="Total:" :colspan="3" footerStyle="text-align:right" />
					<Column footerStyle="text-align:right">
						<template>
							{{this.idMoneda}} {{formatCurrency(this.gran_total)}}						
						</template>
					</Column>									
				</Row>
			</ColumnGroup>					
        </DataTable>				
			</div>
		</div>
	</div>
</template>

<script>
// import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import InvService from '../service/inv.service';
import { CURRENT_APP_MODE } from '../service/constants';

export default {
    // components: {
    //     Loading
    // },
	data() {
		return {
			idMoneda: this.$store.state.auth.currentAppCtx.monedaBase.id_moneda,
            appMode: CURRENT_APP_MODE,
			//lkpCategorias: this.$store.state.auth.currentAppCtx.ctahabiente_categorias,
			lkpCategorias: [
				{id_cta_habiente_categoria: '', categoria: 'Todas'}				
			],
			condiciones: {
				id: '',
				id_mov_plantilla: '',
				id_cta_habiente_info: '',
				id_categoria: '',
				tasa_cambio: 7.82,
				enviar_notificacion: false
			},
			trxs: null,
			cargos: [],
			gran_total: 0.0,
            cargosAEmitir: null,
			expandedRows: [],
			loading: false,
			filters: {},
			submitted: false
		}
	},
	computed:{
		totalTrxAEmitir()
		{
			let movs_a_emitir = this.cargos.filter(element => (element.emitir && !element.emitido));		
			return movs_a_emitir;
		}
	},
	mounted() {
		Array.prototype.push.apply(this.lkpCategorias, this.$store.state.auth.currentAppCtx.ctahabiente_categorias);
	},
	methods: {
        onRowSelect(data) {
            data.excluir = data.excluir == 0? 1: 0;						
			return (data.excluir == 1);			
        },
		rowClass(data)
		{
			var className = (data.emitir?"emitir-row":"regular-row");
			return className;
		},
		findIndexMovimiento(idMovimiento)
		{
			var index = -1;
			for(var i=0; i<this.cargos.length; i++)
			if(this.cargos[i].id_movimiento === idMovimiento) {
				index = i;
				break;
			}

			return index;
		},
		emitir() {
			let i;
			let cargo;
			let emisiones = [];
			var index = -1;
			for (i = 0; i < this.cargos.length; i++) {
				cargo = this.cargos[i];
				if(cargo.emitir && !cargo.emitido) {
					emisiones.push(
						InvService.emitirTrxXID(this.$store.state.auth.currentAppCtx, 
						{
							id_movimiento: `${cargo.id_movimiento}`,
							id: `${cargo.id_contrato_proyeccion}`,
							id_cta_habiente_info: `${cargo.id_cta_habiente_info}`,
							id_mov_plantilla: `${cargo.id_mov_plantilla}`,
							fecha_pago:  `${cargo.fecha_pago}`,
							tasa_cambio: this.condiciones.tasa_cambio,
							enviar_notificacion: cargo.enviar_notificacion

						}).then(data => {															
							index = this.findIndexMovimiento(data.id_movimiento_old);
													
							this.cargos[index].id_movimiento = data.id_movimiento;
							this.cargos[index].doc_emision = data.doc_emision;
							this.cargos[index].doc_fecha = data.doc_fecha;
							this.cargos[index].msg_error = data.msg_error;
							this.cargos[index].emitido = true;

							if(data.msg_error != '')
							console.log(`... error [${cargo.msg_error}]`);
						}).catch(err => {
							cargo.msg_error = err;
							this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
							}
						)					
					);
				}
			}	

			this.loading = true;
			Promise.all(emisiones).finally(() => {
				this.loading = false;
			})
		},
		generar(){
			this.loading = true;
			this.gran_total = 0.0;
			InvService.GetTrxXContrato(this.$store.state.auth.currentAppCtx, this.condiciones).then(data => {					
				this.loading = false;				
				this.cargos = data;	

				this.gran_total = 0.0;	
				let i;
				for (i = 0; i < this.cargos.length; i++) {
					this.gran_total += this.cargos[i].total;
				}													
			}).catch(err => {
				this.loading = false;
				this.cargos = []
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
				}
			);	
		},
        claseResumen(data) {
            return [
                {
                    'vencido': data.id === "0",
                    'lowstock': data.quantity > 0 && data.quantity<10,
                    'instock': data.quantity > 10
                 }
            ];
        },	
        expandAll() {
            this.expandedRows = this.ResumenData.filter(p => p.id);
        },
        collapseAll() {
            this.expandedRows = null;
        },
		formatCurrency(value) {
			return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
		},	
		exportCSV() {
			this.$refs.dt.exportCSV();
		}
	}
}
</script>

<style scoped lang="scss">

::v-deep(.regular-row) {
	font-weight: 300;
	background: white;
}

::v-deep(.emitir-row) {
	font-weight: bold;
	background:white;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);


				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}

::v-deep(.row-anulada) {
    background-color: rgba(0,0,0,.15) !important;
}

.vencido {
    font-weight: 700;
    color: red;
}

</style>    